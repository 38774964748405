import React, { useState } from 'react';
import classNames from "classnames";
import IconChevronDown from '@/lib/assets/images/chev-down.svg';
import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { useClickAway } from "@uidotdev/usehooks";
import SimpleBar from 'simplebar-react';

const Dropdown = ({ heading, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  let ref;
  if (typeof window !== 'undefined') {
    ref = useClickAway(() => {
      setIsOpen(false);
    });
  }

  return (
    <LazyMotion features={() => import("framer-motion").then((mod) => mod.domAnimation)}>
      <div className={classNames(`md:relative md:h-11 z-10`, className)} ref={ref}>
        <div
          className={classNames(
            `md:absolute md:left-0 md:top-0 md:right-0 md:z-10`,
            `bg-white dark:bg-black`,
            `w-full rounded-3xl overflow-hidden`,
            `border-2 border-navy dark:border-silver`
          )}>
          <button
            onClick={toggleDropdown}
            className="w-full px-4 py-[13px] flex justify-between items-center text-navy dark:text-silver"
          >
            <span className="uppercase text-xs font-bold">{heading}</span>
            <m.span
              initial={false}
              animate={{ rotate: isOpen ? 180 : 0 }}
              transition={{ duration: 0.25 }}
            >
              <IconChevronDown />
            </m.span>
          </button>

          <AnimatePresence>
            <m.div
              animate={{ height: (isOpen ? 'auto' : 0) }}
              transition={{ duration: 0.25 }}
              className={`h-0 overflow-hidden`}
            >
                <SimpleBar style={{ maxHeight: 500 }} className={`mom-scrollbar-custom`}>
                <div className="px-4 py-2">
                  {children}
                  </div>
                  </SimpleBar>
            </m.div>
          </AnimatePresence>
        </div>
      </div>
    </LazyMotion>
  );
};

export default Dropdown;
