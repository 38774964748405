'use client';

import {Fragment, memo, useContext, useEffect, useId, useState} from "react";
import Script from "next/script";
import classNames from "classnames";
import Image from 'next/image';

import {useStickyFooterAdStore} from "@/lib/stores/layout";
import {LAST_ROW_AD_INDEX} from "@/lib/utils/constants";
import {useViewPortChange} from "@/lib/utils/hooks";
import iconClose from '@/lib/assets/images/icon-close.svg?url';
import Button from "@/components/Button/Button";
import {PageLevelAdTargetContext, SlotLevelAdTargetContext} from "@/lib/utils/context";

if ('undefined' !== typeof window) {
  // GPT command array.
  window.googletag = window.googletag || {cmd: []};

  // Define global PBJS (Magnite)
  window.pbjs = window.pbjs || { que: [] };

  pbjs.que.push(function() {
    pbjs.setConfig({
      consentManagement: {
        gdpr: {
          cmpApi: 'static', // Use static because you're getting consent from GPT
          consentData: {
            getTCData: {
              tcString: '', // This will be populated by GPT
              gdprApplies: false, // This will be set dynamically
              purpose: {
                consents: {} // This will be populated
              }
            }
          },
          allowAuctionWithoutConsent: true, // Allow auction to proceed if consent isn't ready yet
          timeout: 500 // Short timeout since we're expecting GPT to handle this
        },
        usp: {
          cmpApi: 'static',
          timeout: 500
        }
      }
    });
  });

  // GAM var
  window.gamVars = window.gamVars || {};

  // Ad slots
  window.adSlots = window.adSlots || {};
}

function getSlotId(adUnitPath, randomId = true) {
  const random = useId();

  const uniquePart = randomId ? random : ``; // TODO: Replace empty string with article ID if prefetch implemented

  return getSanitizeClassName(`gam__${adUnitPath}__${uniquePart}`);
}

function getSanitizeClassName(className) {
  return className.toLowerCase().replace(/([^a-z0-9-_])/gi, '_');
}

function getAdSlots() {
  let slots = {
    'gam_home_oop': {
      'ad_unit_path': '/22715092907/ManOfMany/home/oop',
      'refresh': false
    },
    'gam_home_header_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/home/header_desk',
      'refresh': false,
      'min_height': {768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'headerdesk',
      'prefetch': true
    },
    'gam_home_header_sticky_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/home/header_mob',
      'refresh': true,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_home_footer_sticky_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/home/footer_desk',
      'refresh': true,
      'min_height': {0: 90},
      'default_size': [728, 90],
      'mapping_size_key': 'footerdesk',
      'prefetch': true
    },
    'gam_home_footer_sticky_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/home/footer_mob',
      'refresh': true,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_home_midpage_1': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_1',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_home_midpage_2': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_2',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_home_midpage_3': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_3',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_home_midpage_4': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_4',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_home_midpage_5': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_5',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_home_midpage_6': {
      'ad_unit_path': '/22715092907/ManOfMany/home/midpage_6',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_home_above_web_stories': {
      'ad_unit_path': '/22715092907/ManOfMany/home/above_web_stories',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_home_1x1': {
      'ad_unit_path': '/22715092907/ManOfMany/home/1x1',
      'refresh': false,
      'default_size': [1, 1]
    },
    'gam_archive_oop': {
      'ad_unit_path': '/22715092907/ManOfMany/category/oop',
      'refresh': false
    },
    'gam_archive_header_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/category/header_desk',
      'refresh': false,
      'min_height': {768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'headerdesk',
      'prefetch': true
    },
    'gam_archive_header_sticky_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/category/header_mob',
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_archive_footer_sticky_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/category/footer_desk',
      'refresh': true,
      'min_height': {0: 90},
      'default_size': [728, 90],
      'mapping_size_key': 'footerdesk',
      'prefetch': true
    },
    'gam_archive_footer_sticky_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/category/footer_mob',
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_archive_midpage_1': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_1',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_archive_midpage_2': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_2',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_archive_midpage_3': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_3',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_archive_midpage_4': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_4',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_archive_midpage_5': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_5',
      'refresh': false,
      'min_height': {0: 300},
      'default_size': [300, 300],
      'mapping_size_key': 'midpagemob'
    },
    'gam_archive_midpage_6': {
      'ad_unit_path': '/22715092907/ManOfMany/category/midpage_6',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_archive_above_web_stories': {
      'ad_unit_path': '/22715092907/ManOfMany/category/above_web_stories',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_archive_1x1': {
      'ad_unit_path': '/22715092907/ManOfMany/category/1x1',
      'refresh': false,
      'default_size': [1, 1]
    },
    'gam_article_1_oop': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/oop',
      'refresh': false
    },
    'gam_article_1_header_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/header_desk',
      'refresh': false,
      'default_size': [970, 250],
      'mapping_size_key': 'headerdesk',
      'min_height': {768: 90, 992: 250},
      'prefetch': true
    },
    'gam_article_1_footer_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/footer_desk',
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    },
    'gam_article_1_footer_sticky_desktop': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/footer_sticky_desk',
      'refresh': true,
      'min_height': {0: 90},
      'default_size': [728, 90],
      'mapping_size_key': 'footerdesk',
      'prefetch': true
    },
    'gam_article_1_header_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/header_mob',
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_article_1_footer_mobile': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/footer_mob',
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor',
      'prefetch': true
    },
    'gam_article_1_sidebar_1': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/sidebar_1',
      'refresh': false,
      'default_size': [300, 250],
      'mapping_size_key': 'sidebarmrec',
      'min_height': {992: 250}
    },
    'gam_article_1_sidebar_2': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/sidebar_2',
      'refresh': true,
      'default_size': [300, 600],
      'mapping_size_key': 'sidebarhalf',
      'min_height': {992: 250}
    },
    'gam_article_1_1x1': {
      'ad_unit_path': '/22715092907/ManOfMany/article_1/1x1',
      'refresh': false,
      'default_size': [1, 1]
    }
  };

  // Generate in_content slots for article 1
  for (let j = 1; j <= 20; j++) {
    slots[`gam_article_1_incontent_${j}`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_1/incontent_${j}`,
      'refresh': false,
      'default_size': [300, 250],
      'mapping_size_key': 'incontent',
      'min_height': {0: 300, 1200: 90}
    };
  }

  // Generate article slots
  let fallbackStart = 2;
  // let fallbackCount = mom_get_current_post_order_index() + 3; // TODO: implement a global state count for posts loaded
  let fallbackCount = 10;

  for (let i = fallbackStart; i <= fallbackStart + fallbackCount; i++) {
    let article_slots = {};

    article_slots[`gam_article_${i}_oop`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/oop`,
      'refresh': false
    };
    article_slots[`gam_article_${i}_header_desktop`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/header_desk`,
      'refresh': false,
      'default_size': [970, 250],
      'mapping_size_key': 'headerdesk',
      'min_height': {768: 90, 992: 250}
    };
    article_slots[`gam_article_${i}_footer_desktop`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/footer_desk`,
      'refresh': false,
      'min_height': {0: 300, 768: 90, 992: 250},
      'default_size': [970, 250],
      'mapping_size_key': 'midpage'
    };
    article_slots[`gam_article_${i}_footer_sticky_desktop`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/footer_sticky_desk`,
      'refresh': true,
      'min_height': {0: 90},
      'default_size': [728, 90],
      'mapping_size_key': 'footerdesk'
    };
    article_slots[`gam_article_${i}_header_mobile`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/header_mob`,
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor'
    };
    article_slots[`gam_article_${i}_footer_mobile`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/footer_mob`,
      'refresh': false,
      'min_height': {0: 50},
      'default_size': [320, 50],
      'mapping_size_key': 'anchor'
    };
    article_slots[`gam_article_${i}_sidebar_1`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/sidebar_1`,
      'refresh': false,
      'default_size': [300, 250],
      'mapping_size_key': 'sidebarmrec',
      'min_height': {992: 250}
    };
    article_slots[`gam_article_${i}_sidebar_2`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/sidebar_2`,
      'refresh': true,
      'default_size': [300, 600],
      'mapping_size_key': 'sidebarhalf',
      'min_height': {992: 250}
    };
    article_slots[`gam_article_${i}_1x1`] = {
      'ad_unit_path': `/22715092907/ManOfMany/article_${i}/1x1`,
      'refresh': false,
      'default_size': [1, 1]
    };

    // Generate in_content slots for article i
    for (let j = 1; j <= 20; j++) {
      article_slots[`gam_article_${i}_incontent_${j}`] = {
        'ad_unit_path': `/22715092907/ManOfMany/article_${i}/incontent_${j}`,
        'refresh': false,
        'default_size': [300, 250],
        'mapping_size_key': 'incontent',
        'min_height': {0: 300, 1200: 90}
      };
    }

    slots = {...slots, ...article_slots};
  }

  return slots;
}

function getAdSizes() {
  return {
    headerdesk: [
      [[992, 0], [970, 250]],
      [[768, 0], [728, 90]],
      [[0, 0], []],
    ],
    footerdesk: [
      [[768, 0], [728, 90]],
      [[0, 0], []],
    ],
    anchor: [
      [[768, 0], []],
      [[0, 0], [[320, 50], [300, 50]]],
    ],
    midpage: [
      [[992, 0], [[970, 250], [970, 90], [728, 90]]],
      [[768, 0], [728, 90]],
      /* [[0, 0], [[336, 280], [300, 300], [300, 250], [320, 100], [320, 50], [300, 50]]], */
      [[0, 0], [[336, 280], [300, 300], [300, 250], [320, 100]]],
    ],
    midpagemob: [
      [[768, 0], []],
      /* [[0, 0], [[336, 280], [300, 300], [300, 250], [320, 100], [320, 50], [300, 50]]], */
      [[0, 0], [[336, 280], [300, 300], [300, 250], [320, 100]]],
    ],
    sidebarmrec: [
      [[992, 0], [300, 250]],
      [[0, 0], []],
    ],
    sidebarhalf: [
      [[992, 0], [[300, 600], [160, 600]]],
      [[0, 0], []],
    ],
    incontent: [
      [[1199, 0], [[728, 90], [640, 360], "fluid"]],
      [[992, 0], [[336, 280], [300, 300], [300, 250], [320, 100], [576, 324], "fluid"]],
      [[0, 0], [[336, 280], [300, 300], [300, 250], [320, 100], [320, 180], "fluid"]],
    ],
  };
}

function SlotWrapper({children, hasWrapper}) {
  if (hasWrapper) {
    return (
      <div className={'mom-gpt__wrapper'}>
        {children}
      </div>
    );
  }

  return <>{children}</>;
}

function demandManagerRequest(slots) {
  // function that handles the failsafe using boolean logic per auction
  function failsafeHandler(callback, initialSlots) {
    let adserverRequestSent = false;
    return (bidsBackSlots) => {
      if (adserverRequestSent) return;
      adserverRequestSent = true;

      if (Array.isArray(bidsBackSlots) && bidsBackSlots.length > 0) {
        callback(bidsBackSlots);
      } else {
        callback(initialSlots);
      }
    };
  }

  // provide failsafeHandler with callback function to fire when we want to make 
  // the ad server request, as well as headerBiddingSlots to use in case of failsafe 
  const sendAdServerRequest = failsafeHandler((slotsToRefresh) => {
    googletag.pubads().refresh(slotsToRefresh);
  }, slots);

  // Wait for GPT to be ready, which should include consent data
  googletag.cmd.push(function() {
    // Check if GPT has consent data available
    if (typeof googletag.pubads().getTargeting('consent_string') !== 'undefined' || 
        typeof googletag.pubads().getTargeting('usp_string') !== 'undefined') {
      
      // Get consent string from GPT if available
      const tcString = googletag.pubads().getTargeting('consent_string')[0] || '';
      const uspString = googletag.pubads().getTargeting('usp_string')[0] || '';
      
      // Update Prebid with the consent data from GPT
      pbjs.que.push(function () {
        if (tcString) {
          pbjs.setConfig({
            consentManagement: {
              gdpr: {
                consentData: {
                  getTCData: {
                    tcString: tcString,
                    gdprApplies: true
                  }
                }
              }
            }
          });
        }
        
        if (uspString) {
          pbjs.setConfig({
            consentManagement: {
              usp: {
                consentData: {
                  getUSPData: {
                    uspString: uspString
                  }
                }
              }
            }
          });
        }
        
        // Now request bids with consent data
        pbjs.rp.requestBids({
          callback: sendAdServerRequest,
          gptSlotObjects: slots
        });
      });
    } else {
      // If no consent data is available from GPT, proceed anyway
      pbjs.que.push(function () {
        pbjs.rp.requestBids({
          callback: sendAdServerRequest,
          gptSlotObjects: slots
        });
      });
    }
  });

  // start the failsafe timeout
  setTimeout(sendAdServerRequest, 2000);
}

const InitializeGPT = memo(function () {
  const pageLevelAdTargetContext = useContext(PageLevelAdTargetContext);

  /* Too agressive
  useViewPortChange(() => {
    googletag.cmd.push(function () {
      googletag.pubads().refresh();
    });
  });
  */

  // Loading
  useEffect(() => {
    // GAM Targeting
    window.gamVars = {
      'site': 'ManOfMany',
      'kv': pageLevelAdTargetContext || {}
    };

    // GAM vars
    const viewportWidth = document.documentElement.clientWidth;
    const showSkin = viewportWidth >= 1366 ? 'Y' : 'N';
    const fn_pageskin = viewportWidth >= 1366 ? 'true' : 'false'; // To implement SRT in DFP

    // In-view refresh settings
    const adRefreshKey = 'refresh';
    const adRefreshFlag = 'true';
    const adRefreshTime = 60;
    const onViewableListener = (event) => {
      googletag.pubads().removeEventListener("impressionViewable", onViewableListener);

      const slot = event.slot;
      if (slot.getTargeting(adRefreshKey).indexOf(adRefreshFlag) > -1) {
        setTimeout(function () {
          googletag.pubads().refresh([slot]);
        }, adRefreshTime * 1000);
      }
    };

    // Initialize ad configuration
    googletag.cmd.push(function () {
      googletag.pubads().setTargeting('skin', [showSkin]);
      googletag.pubads().setTargeting('inskin_yes', fn_pageskin);

      // kvAdTest for testing
      const searchParams = new URL(document.location).searchParams;
      if (searchParams.get("adtest")) {
        googletag.pubads().setTargeting('adtest', [searchParams.get("adtest")]);
      }

      googletag.pubads().setCentering(true);
      googletag.pubads().collapseEmptyDivs();

      // Enable lazy-loading for all ad slots (at page level)
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 200,
        renderMarginPercent: 50,
        mobileScaling: 2.0
      });

      // TODO: add add back logging for slotRequested and slotOnload

      // Extra configs
      googletag.setAdIframeTitle('Advertisement');

      // configure GPT following PBJS best practices
      googletag.pubads().disableInitialLoad();
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();

      // Events listeners
      googletag.pubads().addEventListener('impressionViewable', onViewableListener);
    });
  }, []);

  // TODO: Implement APS

  return (
    <>
      <Script
        src={'https://securepubads.g.doubleclick.net/tag/js/gpt.js'}
        strategy="beforeInteractive" // Load as early as possible
        async
      />

      <Script
        src={`https://micro.rubiconproject.com/prebid/dynamic/25962.js`}
        async
      />
    </>
  );
});

const AdSlot = memo(function (
  {
    adUnit,
    hasWrapper = true,
    hasAdLabel = true,
    adLabelPosition = 'top',
    adTargeting = {},
    isOutOfSpace = false,
  }
) {
  const slotLevelAdTargetContext = useContext(SlotLevelAdTargetContext);
  const targeting = Object.assign(slotLevelAdTargetContext, adTargeting);
  const slotsMapping = getAdSlots();

  // Bail if the ad unit is not defined in the slots mapping
  if (!slotsMapping?.[adUnit]) {
    return null;
  }

  // Get slot data
  const slotData = slotsMapping[adUnit];

  // Ad ID
  const slotId = getSlotId(slotData?.['ad_unit_path']);

  // Defined ad classes
  let innerClasses = ['mom-gpt__inner'];
  let adLabelHeight = 0;

  if (hasAdLabel) {
    innerClasses.push('mom-gpt__inner--labeled');
    innerClasses.push(`mom-gpt__inner--labeled--${adLabelPosition}`);
    adLabelHeight = (adLabelPosition === 'bottom') ? 30 : 25;
  }

  useEffect(() => {
    if (window.adSlots[slotId]) {
      return;
    }

    // Register the slot with GPT when the component is loaded.
    googletag.cmd.push(function () {
      const adSizes = getAdSizes();
      let slot;

      if (isOutOfSpace) {
        slot = googletag.defineOutOfPageSlot(
          slotData['ad_unit_path'],
          slotId
        );
      } else {
        slot = googletag.defineSlot(
          slotData['ad_unit_path'],
          slotData?.['default_size'] ?? [320, 50],
          slotId
        );

        if (slotData?.['mapping_size_key']) {
          slot?.defineSizeMapping(adSizes[slotData['mapping_size_key']]);
        }

        Object.keys(targeting).forEach(function (key) {
          slot.setTargeting(key, targeting[key]);
        });
      }

      slot.setTargeting('refresh', (slotData?.['refresh'] && slotData['refresh']) ? 'true' : 'false');

      slot.addService(googletag.pubads());

      // googletag.display(slotId);

      demandManagerRequest([slot]);

      window.adSlots[slotId] = slot;
    });

    // Clean up the slot when the component is unloaded.
    return () => {
      googletag.cmd.push(function () {
        if (window.adSlots[slotId]) {
          googletag.destroySlots([window.adSlots[slotId]]);
          delete window.adSlots[slotId];
        }
      });
    };
  }, []);

  // Create the ad slot container, do not check currentObjectLoaded here (so SSR can run)
  return (
    isOutOfSpace ? (
      <div id={slotId}/>
    ) : (
      <SlotWrapper hasWrapper={hasWrapper}>
        <div
          className={classNames(innerClasses)}
          style={{minHeight: `calc(var(--${slotId}) + ${adLabelHeight}px)`}}
        >
          <div id={slotId}/>

          {slotData?.['min_height'] && Object.keys(slotData['min_height']).map((key, index) => {
            return (
              <Fragment key={index}>
                <style jsx global>
                  {`@media screen and (min-width: ${key}px) {
                    .mom-gpt__inner {
                      --${slotId}: ${slotData['min_height'][key]}px
                    }
                  }`}
                </style>
              </Fragment>
            );
          })}
        </div>
      </SlotWrapper>
    )
  );
});

AdSlot.GridInline = memo(function ({adRowIndex = 0, objectType = 'archive'}) {
  const pos = adRowIndex + 1;
  const mobileOnly = 1 === pos % 2;
  let slotKey = 'archive';

  switch (objectType) {
    case 'home':
      slotKey = objectType;
      break;
  }

  return (
    <div className={classNames({"md:hidden": mobileOnly,})}>
      <AdSlot adUnit={`gam_${slotKey}_midpage_${pos}`}/>
    </div>
  );
});

AdSlot.PostSideBar = memo(function ({postPositionIndex = 0, adIndex = 0}) {
  // const postPos = parseInt(postPositionIndex + '') + 1; // TODO: remove this
  const postPos = 1;
  const adPos = parseInt(adIndex + '') + 1;
  const adUnit = `gam_article_${postPos}_sidebar_${adPos}`;

  return (
    <AdSlot
      hasWrapper={false}
      adUnit={adUnit}
      adLabelPosition={'bottom'}
      adTargeting={{scroll: postPos}}
    />
  );
});

AdSlot.PostInline = memo(function ({postPositionIndex = 0, adIndex = 0}) {
  // const postPos = parseInt(postPositionIndex + '') + 1; // TODO: remove this
  const postPos = 1;
  const adPos = parseInt(adIndex + '') + 1;
  const adUnit = `gam_article_${postPos}_incontent_${adPos}`;

  return (
    <AdSlot adUnit={adUnit} adTargeting={{scroll: postPos}}/>
  );
});

AdSlot.HeaderDesktop = memo(function () {
  return (
    <div className="hidden md:block">
      <AdSlot adUnit={`gam_home_header_desktop`}/>
    </div>
  );
});

AdSlot.FooterDesktop = memo(function ({objectType = 'archive'}) {
  const pos = LAST_ROW_AD_INDEX + 1;
  let adUnit = `gam_archive_midpage_${pos}`;

  switch (objectType) {
    case 'home':
      adUnit = `gam_home_midpage_${pos}`;
      break;

    case 'article':
      adUnit = `gam_article_1_footer_desktop`;
      break;
  }

  return (
    <div className="hidden md:block">
      <AdSlot adUnit={adUnit}/>
    </div>
  );
});

AdSlot.OneByOne = memo(function ({objectType = 'archive'}) {
  let adUnit = `gam_archive_1x1`;

  switch (objectType) {
    case 'home':
      adUnit = `gam_home_1x1`;
      break;

    case 'article':
      adUnit = `gam_article_1_1x1`;
      break;
  }

  return (
    <div className="mom-gpt__1x1">
      <AdSlot
        adUnit={adUnit}
        adTargeting={{scroll: 1}}
        hasWrapper={false}
        hasAdLabel={false}
      />
    </div>
  );
});

AdSlot.OutOfSpace = memo(function ({objectType = 'archive'}) {
  let adUnit = `gam_archive_oop`;

  switch (objectType) {
    case 'home':
      adUnit = `gam_home_oop`;
      break;

    case 'article':
      adUnit = `gam_article_1_oop`;
      break;
  }

  return <AdSlot adUnit={adUnit} isOutOfSpace={true}/>;
});

AdSlot.FooterSticky = memo(function ({postPositionIndex = 0, objectType = 'archive'}) {
  const [dismissedStickyAd, setDismissedStickyAd] = useState(false);
  // const postPos = parseInt(postPositionIndex + '') + 1; // TODO: remove this
  const postPos = 1;
  const stickyFooterAdVisible = useStickyFooterAdStore(state => state.visible);

  let adUnitDesktop = `gam_archive_footer_sticky_desktop`;
  let adUnitMobile = `gam_archive_footer_sticky_mobile`;

  switch (objectType) {
    case 'home':
      adUnitDesktop = `gam_${objectType}_footer_sticky_desktop`;
      adUnitMobile = `gam_${objectType}_footer_sticky_mobile`;
      break;

    case 'article':
      adUnitDesktop = `gam_article_${postPos}_footer_sticky_desktop`;
      adUnitMobile = `gam_article_${postPos}_footer_mobile`;
      break;
  }

  return (
    <>
      <div
        className={classNames(
          'hidden md:block mom-sticky-gpt mom-sticky-gpt__desktop--bottom mb-8',
          {'mom-sticky-gpt--visible': stickyFooterAdVisible},
          {'mom-sticky-gpt--dismissed': dismissedStickyAd}
        )}
      >
        <Button onClick={() => setDismissedStickyAd(true)} variant={'icon'} className={'mom-gpt__dismiss'}>
          <Image width={10} height={10} src={iconClose} alt={'Close'}/>
        </Button>
        <AdSlot adUnit={adUnitDesktop} hasWrapper={false} hasAdLabel={false}/>
      </div>

      <div
        className={classNames(
          'md:hidden mom-sticky-gpt mom-sticky-gpt__mobile--bottom mb-4',
          {'mom-sticky-gpt--visible': stickyFooterAdVisible},
          {'mom-sticky-gpt--dismissed': dismissedStickyAd}
        )}
      >
        <Button onClick={() => setDismissedStickyAd(true)} variant={'icon'} className={'mom-gpt__dismiss !top-[-10px] flex justify-end items-center'}>
          <Image width={10} height={10} src={iconClose} alt={'Close'}/>
        </Button>
        <AdSlot adUnit={adUnitMobile} hasWrapper={false} hasAdLabel={false}/>
      </div>
    </>
  );
});

export {AdSlot, InitializeGPT};
