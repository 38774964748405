import classNames from 'classnames';
import {twMerge} from 'tailwind-merge';

export default function Container({children, className, edgePadding = true}) {
  return (
    <div className={twMerge(classNames(
      'container xl:px-container',
      {'mx-4 max-md:w-auto md:mx-auto md:px-4': edgePadding},
    ), className)}
    >
      {children}
    </div>
  );
};
