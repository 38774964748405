import {gql} from '@apollo/client';
import * as constants from '@/lib/utils/constants';
import {FooterFragment, GlobalFragment} from '@/lib/fragments/SiteSettings';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Main from '@/components/Main/Main';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import Video, {VideoMemo} from '@/components/Video/Video';
import SEO from '@/components/SEO/SEO';
import {useCallback, useState} from "react";
import dynamic from "next/dynamic";
import TermLinks from "@/components/TermLinks/TermLinks";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  // Main post
  const {data} = props;
  const {
    video,
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    momSubNavHeader: subNavHtml,
    popularTags = [],
  } = data || {};

  return (
    <>
      <SEO
        seo={{...video?.seo}}
        title={video?.title}
        description={video?.title}
        useShareDataTitle={true}
      />

      <Header {...{primaryMenu, subNavHtml, subMenuA, subMenuB, siteSettings, popularTags}} enableShare={true}/>

      <Main>
        <VideoMemo
          video={video}
          siteSettings={siteSettings}
        />
      </Main>

      <Footer {...{subMenuA, subMenuB, siteSettings}} style={{marginTop: 0}}/>
    </>
  );
}

Component.query = gql`
  ${FooterFragment}
  ${GlobalFragment}
  ${Video.fragments.entryVideo}
  ${NavigationMenu.fragments.entry}
  ${TermLinks.fragments.entryRootTag}
  query GetVideo(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ) {
    video(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      ...${Video.fragments.keyVideo}
    }
    momSubNavHeader(objectDatabaseID: $databaseId)
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

Component.variables = ({databaseId}, ctx) => {
  return {
    databaseId,
    excludes: [databaseId],
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
