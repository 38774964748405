import '../faust.config';
import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import {FaustProvider} from '@faustwp/core';
import {ThemeProvider} from 'next-themes';
// import '@faustwp/core/dist/css/toolbar.css'; // Moved to simplified toolbar.scss
import dynamic from "next/dynamic";
import NextNProgress from 'nextjs-progressbar';
import 'simplebar-react/dist/simplebar.min.css';

import '@/lib/assets/styles/global.scss';
import {InitializeGPT} from "@/components/GPT/GPT";
import {PageLevelAdTargetContext} from '@/lib/utils/context';
import {sendIpsosEvent} from "@/lib/utils/helpers";

const Snippets = dynamic(() => import('@/components/Snippets/Snippets').then((mod) => mod.default), {ssr: false});
const Utils = dynamic(() => import('@/components/Utils/Utils').then((mod) => mod.default));

export default function MyApp({Component, pageProps}) {
  const router = useRouter();

  // Page level ad targeting
  // All objects should have momAdTargeting(objectDatabaseID) in their gql for this to work
  const momAdTargeting = pageProps?.__TEMPLATE_QUERY_DATA__?.momAdTargeting;
  const pageLevelTargeting = JSON.parse(momAdTargeting ?? '{}') ?? {};

  useEffect(() => {
    // Load page level ad targeting
    if (Object.keys(pageLevelTargeting).length) {
      window.googletag = window.googletag || {cmd: []};

      googletag.cmd.push(function () {
        Object.keys(pageLevelTargeting).forEach(function (key) {
          googletag.pubads().setTargeting(key, pageLevelTargeting[key]);
        });
      });
    }

    // IPSOS on template change
    const {section} = pageLevelTargeting || {};
    sendIpsosEvent(section);
  }, [momAdTargeting]);

  return (
    <ThemeProvider defaultTheme={'system'} disableTransitionOnChange>
      <FaustProvider pageProps={pageProps}>
        <NextNProgress height={3} color={'#3D6D8C'} options={{showSpinner: false}}/>
        <Utils/>

        <PageLevelAdTargetContext.Provider value={pageLevelTargeting}>
          <InitializeGPT/>

          <Component {...pageProps} key={router.asPath}/>
          
          {(process.env.NEXT_PUBLIC_APP_ENV === 'production') && <Snippets/>}
        </PageLevelAdTargetContext.Provider>
      </FaustProvider>
    </ThemeProvider>
  );
}
