import classNames from 'classnames';
import PropTypes from "prop-types";
import {gql} from "@apollo/client";
import dynamic from "next/dynamic";

import {getReactiveContent, getStyledTitle} from "@/lib/utils/helpers";
import Heading from '@/components/Heading/Heading';
import TermLinks from '@/components/TermLinks/TermLinks';
import FeaturedImage from "@/components/FeaturedImage/FeaturedImage";
import Carousel from "@/components/Carousel/Carousel";
import PostInfo from "@/components/PostInfo/PostInfo";
import SEO from "@/components/SEO/SEO";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";

// const Wrapper = ({hasContainer, children}) => hasContainer ? <Container>{children}</Container> : <>{children}</>;
const YouTubeEmbed = dynamic(() => import('react-social-media-embed').then(mod => mod.YouTubeEmbed), {ssr: false});
const PostActions = dynamic(() => import('@/components/PostActions/PostActions').then((mod) => mod.default));

export default function PostHeader({post, preloadImage = true}) {
  const {
    title,
    featuredImage,
    categories,
    momHasVideo,
    momHasCarousel,
    seo
  } = post;

  // Custom fields
  const {
    isFeaturedPost,
    normalPostBlock: {
      showingBig: normalFullWidth,
      postVideo: normalVideo,
      subtitle: normalSubtitle,
      postCarousel
    } = {},
    featuredPostBlock: {
      fullOrStandard: featuredFullWidth,
      video: featuredVideo,
      partnerHeadline: featuredSubtitle,
      carousel
    } = {}
  } = post?.postSettings || {};

  // Choose data
  let isFullWidth, videoUrl, subtitle, wrapFeaturedImage, wrapMeta, gallery;

  if (isFeaturedPost) {
    isFullWidth = featuredFullWidth?.toLowerCase() === 'full';
    videoUrl = featuredVideo;
    subtitle = featuredSubtitle;
    // wrapFeaturedImage = !isFullWidth;
    // wrapMeta = true;
    gallery = carousel;
  } else {
    isFullWidth = normalFullWidth?.toLowerCase() === 'big';
    videoUrl = normalVideo;
    subtitle = normalSubtitle;
    // wrapFeaturedImage = isFullWidth;
    // wrapMeta = isFullWidth;
    gallery = postCarousel;
  }

  // Image sizes
  const imageSize = isFullWidth ? {width: 1160, height: 773} : {width: 835, height: 626};

  // Media
  let EntryHeaderMedia;
  if (momHasCarousel) {
    EntryHeaderMedia = () => (
      <Carousel gallery={gallery}/>
    );
  } else if (momHasVideo) {
    EntryHeaderMedia = () => (
        <YouTubeEmbed
          /* placeholderImageUrl={featuredImage?.node?.sourceUrl}
          placeholderSpinnerDisabled={true} */
          placeholderDisabled={true}
          className={classNames('mb-4 w-full aspect-video relative', 'max-w-[1160px] mx-auto')}
          url={videoUrl}
          width="100%"
          height="100%"
          youTubeProps={{
            style: null,
            iframeClassName: 'absolute inset-0',
            opts: {
              playerVars: {
                autoplay: 0,
                controls: 0,
              }
            }
          }}
        />
    );
  } else {
    EntryHeaderMedia = () => (
      <FeaturedImage
        {...imageSize}
        priority={preloadImage}
        image={featuredImage?.node}
        className={classNames('md:mb-4')}
        shareEnabled={true}
        sizes={'(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 65vw'}
      />
    );
  }

  const isVariantLarge = isFullWidth || isFeaturedPost;
  return (
    <div className={classNames(
      {'text-center': isVariantLarge},
      'mb-5',
    )}>
      <Breadcrumbs
        list={seo?.breadcrumbs}
        hideLast={true}
      />

      <div className={classNames(
        `flex flex-nowrap flex-col-reverse md:flex-col text-center`,
        {'md:text-left': !isVariantLarge}
      )}>
        <EntryHeaderMedia/>

        {!isFeaturedPost && <TermLinks className={classNames('order-last md:order-none mb-2')} terms={categories}/>}

        <div className={classNames(
          'gap-3 md:gap-5 mb-3',
          {'flex flex-nowrap justify-between flex-col md:flex-row items-center md:items-end mb-2 md:mb-0': !!subtitle && !isVariantLarge}
        )}>
          {!!title && (
            <Heading level={'h1'} className={classNames(
              'mb-0 text-h2 md:text-h1',
              {'md:max-w-[700px]': !!subtitle && !isVariantLarge}
            )}>{getStyledTitle({title})}</Heading>
          )}

          {!!subtitle && (
            <div className={classNames(
              {'md:text-right max-w-[250px] text-sm': !isVariantLarge},
              'shrink-0 leading-4 py-2',
              '[&_img]:inline-block [&_img]:w-auto [&_img]:h-6 [&_img]:align-[-5px] [&_img]:mx-1 dark:[&_img]:brightness-0 dark:[&_img]:invert',
              '[&_a:has(img)]:no-underline'
            )}>{getReactiveContent({htmlString: subtitle})}</div>
          )}
        </div>
      </div>
    </div>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired,
  preloadImage: PropTypes.bool
};

PostHeader.fragments = {
  key: 'PostHeaderFragment',
  entry: gql`
    ${Carousel.fragments.entry}
    ${FeaturedImage.fragments.entry}
    ${TermLinks.fragments.entryCategory}
    ${SEO.fragments.entryPostType}
    ${PostInfo.fragments.entry}
    fragment PostHeaderFragment on Post {
      ...${PostInfo.fragments.key}
      title
      momHasVideo
      momHasCarousel
      postSettings {
        isFeaturedPost
        featuredPostBlock {
          fullOrStandard
          video
          partnerHeadline
          carousel {
            ...${Carousel.fragments.key}
          }
        }
        normalPostBlock {
          showingBig
          postVideo
          subtitle
          postCarousel {
            ...${Carousel.fragments.key}
          }
        }
      }
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      categories {
        ...${TermLinks.fragments.keyCategory}
      }
      seo {
        ...${SEO.fragments.keyPostType}
      }
    }
  `
};
