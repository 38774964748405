import className from 'classnames/bind';
import styles from './HeaderSubNav.module.scss';
import Container from "@/components/Container/Container";
import parse from "html-react-parser";
import Link from "next/link";
import {useRouter} from "next/router";
import {getRemovedHostContent} from "@/lib/utils/helpers";

const cx = className.bind(styles);

export default function HeaderSubNav(
  {
    className,
    html,
    isSticky = false
  }
) {
  const router = useRouter();
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === 'a') {
        const href = domNode.attribs.href;
        const isActive = router.asPath.startsWith(href);

        // Create a new className combining the existing classes (if any) with 'active' if it's the active link
        const className = `${domNode.attribs.class || ''} ${isActive ? 'font-bold text-navy dark:text-silver' : ''}`.trim();

        // Return a Next.js Link component
        return (
          <Link href={href} className={className}>
            {domNode.children[0].data}
          </Link>
        );
      }
    }
  };

  return (
    <div className={cx(
      'bg-white dark:bg-black',
      {'lg:sticky lg:top-offset-t-mini lg:z-10': isSticky},
      className
    )}>
      <Container
        edgePadding={false}
        className={cx(
          'component',
          'mom-border border-b-2',
          className
        )}
      >
        {parse(getRemovedHostContent(html), options)}

        {/* {(!isSticky) && (
          <style jsx global>{`
            :root {
              --mom--header-subnav-height: 46px;
            }
          `}</style>
        )} */}
      </Container>
    </div>
  );
};
