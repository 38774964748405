import {gql} from '@apollo/client';
import * as constants from '@/lib/utils/constants';
import {BlogInfoFragment} from '@/lib/fragments/GeneralSettings';
import {FooterFragment, GlobalFragment} from '@/lib/fragments/SiteSettings';
import Container from '@/components/Container/Container';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Main from '@/components/Main/Main';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import SEO from '@/components/SEO/SEO';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import EntryHeader from '@/components/EntryHeader/EntryHeader';
import TermLinks from "@/components/TermLinks/TermLinks";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  const {data} = props;
  const {uri, template: {__typename} = {}} = props?.__SEED_NODE__ || {};
  const {title, content, seo, pageSettings: {pageBrief}} = data?.page || {};
  const {
    generalSettings: {title: siteTitle, description: siteDescription} = {},
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    momSubNavHeader: subNavHtml,
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    popularTags = [],
  } = data || {};

  const isFullWidthContainer = __typename === 'Template_PageACFFlexibleBlocks';

  return (
    <>
      <SEO
        seo={seo}
        url={uri}
        title={siteTitle}
        description={siteDescription}
      />

      <Header {...{primaryMenu, subNavHtml, subMenuA, subMenuB, siteSettings, popularTags}}/>

      <Main>
        <Container>
          <Breadcrumbs list={seo?.breadcrumbs}/>
          <EntryHeader title={title} description={pageBrief}/>

          <ContentWrapper content={content} isFullWidth={isFullWidthContainer}/>
        </Container>
      </Main>

      <Footer {...{subMenuA, subMenuB, siteSettings}}/>
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${FooterFragment}
  ${GlobalFragment}
  ${SEO.fragments.entryPostType}
  ${NavigationMenu.fragments.entry}
  ${TermLinks.fragments.entryRootTag}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      seo {
        ...${SEO.fragments.keyPostType}
      }
      pageSettings {
        pageBrief
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    momSubNavHeader(objectDatabaseID: $databaseId)
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

Component.variables = ({databaseId}, ctx) => {
  return {
    databaseId,
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
