import {gql} from '@apollo/client';
import Link from "next/link";
import Heading from "@/components/Heading/Heading";
import {getRemovedHostContent, getStyledTitle} from "@/lib/utils/helpers";
import Image from "next/image";
import classNames from "classnames";

export default function HeroBanner({className, settings}) {
  // TODO: improve these props
  const {hero: {image, imageMobile, heading, linkLabel, link, textContent}} = settings || {};
  const imageNode = image?.node || image;
  const imageMobileNode = imageMobile?.node || imageMobile;

  if (!link) {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-8 gap-4 md:gap-5">
        <div className="col-span-1 md:col-span-7 xl:col-span-5">
          <Link href={getRemovedHostContent(link)} className={`block no-underline relative aspect-square md:aspect-[540/240] lg:aspect-[800/350]`}>
            <Image
              src={imageNode?.sourceUrl}
              alt={imageNode?.altText}
              fill
              className={classNames(
                `object-cover`,
                { 'hidden md:block': !imageMobileNode }
              )}
            />
            {imageMobileNode && (
              <Image
                src={imageMobileNode?.sourceUrl}
                alt={imageMobileNode?.altText}
                fill
                className={`object-cover md:hidden`}
              />
            )}
          </Link>
        </div>

        <div className="col-span-1 md:col-span-5 xl:col-span-3">
          {heading && <Heading level={`h2`} className={`md:text-h1 mb-3.5`}><Link href={getRemovedHostContent(link)} className={`no-underline`}>{getStyledTitle({title: heading})}</Link></Heading>}
          {textContent && <p className={`mb-4`}>{textContent}</p>}
          {link && <Link href={getRemovedHostContent(link)} className={`mom-btn mom-btn--fill`}>{linkLabel || 'Learn More'}</Link>}
        </div>
      </div>
    </>
  );
};

HeroBanner.fragments = {
  key: 'HeroBannerFragment',
  entry: gql`
    fragment HeroBannerFragment on HeadlessSiteSettings {
      siteSettings {
        hero {
          image {
            node {
              id
              sourceUrl
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          imageMobile {
            node {
              id
              sourceUrl
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          heading
          linkLabel
          link
          textContent
        }
      }
    }
  `
};
