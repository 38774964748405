import classNames from 'classnames';
import {gql} from "@apollo/client";
import PropTypes from "prop-types";
import PostAuthor from "@/components/PostAuthor/PostAuthor";
import dynamic from "next/dynamic";
import TermLinks from "@/components/TermLinks/TermLinks";

const PostActions = dynamic(() => import('@/components/PostActions/PostActions').then((mod) => mod.default));

export default function PostFooter({post, className}) {
  const {tags, momPublicAuthors} = post;

  return (
    <>
      <div className={classNames(
        'grid grid-cols-1 md:grid-cols-10 gap-4',
        'mom-border border-t-2 py-6 md:py-6 mt-6 md:mt-8',
        className
      )}>
        <div className={classNames('md:col-span-6 text-left')}>
          <TermLinks terms={tags}/>
        </div>

        {/* <PostActions className={`md:col-span-4 text-right`}/> */}
        <PostActions className={`md:col-span-4 md:text-right`}/>
      </div>

      {momPublicAuthors?.map((author, index) => (
        <PostAuthor key={index} publicAuthor={author} showAboutLink={index === momPublicAuthors.length - 1}/>
      ))}
    </>
  );
};

PostFooter.propTypes = {
  post: PropTypes.object.isRequired,
};

PostFooter.fragments = {
  key: 'PostFooterFragment',
  entry: gql`
    ${PostAuthor.fragments.entry}
    ${TermLinks.fragments.entryTag}
    fragment PostFooterFragment on Post {
      momPublicAuthors {
        ...${PostAuthor.fragments.key}
      }
      tags {
        ...${TermLinks.fragments.keyTag}
      }
    }
  `
};
