import PropTypes from "prop-types";
import classNames from 'classnames';

export default function PostAuthorImage({className, size, avatarUrl, alt}) {
  return (
    <figure className={classNames(`aspect-square`, className)} style={{
      width: size,
      height: size,
    }}>
      {/* Do not use Next Image here, so it will compress gravatar, which may cause caching issues */}
      <img
        className={classNames(
          `rounded-full object-cover size-full`,
        )}
        src={avatarUrl}
        alt={alt}
        width={size}
        height={size}
        loading={'lazy'}
      />
    </figure>
  );
}

PostAuthorImage.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  avatarUrl: PropTypes.string,
  alt: PropTypes.string,
};
