import classNames from 'classnames';
import Link from "next/link";
import React from "react";

import {useSearchOverlayStore, useStickyHeaderStore, useStickyNewsletterStore, usePrimaryMenuStore} from "@/lib/stores/layout";
import Container from "@/components/Container/Container";
import Button from "@/components/Button/Button";
import IconSearch from '@/lib/assets/images/icon-search-w.svg';
// import IconClose from '@/lib/assets/images/cross-white.svg';
import IconLogo from '@/lib/assets/images/logo.svg';
import PostShare from "@/components/PostShare/PostShare";
import ThemeToggle from "@/components/ThemeToggle/ThemeToggle";
import HamburgerButton from '@/components/Button/HamburgerButton';

export default function HeaderTop({enableShare = false}) {
  const toggleNewsletter = useStickyNewsletterStore(state => state.toggle);
  const toggleSearch = useSearchOverlayStore(state => state.toggle);
  const isHeaderSticky = useStickyHeaderStore(state => state.sticky);

  // const isSearchVisible = useSearchOverlayStore(state => state.visible);
  
  return (
    <div className={classNames(
      'py-2 transition-all bg-white dark:bg-black',
      {'lg:py-4 xl:py-7': !isHeaderSticky},
      {'lg:py-2': isHeaderSticky}
    )}>
      <Container className={classNames('grid grid-cols-2 lg:grid-cols-3')}>
        <div className={'flex items-center justify-start max-lg:hidden'}>
          <HamburgerButton className={'pl-0'} />
        </div>

        <Link
          href="/"
          className={classNames(
            'h-7 lg:h-100% flex items-center',
            'lg:mx-auto transition-all md:transform-gpu',
            'lg:col-start-2 !text-black dark:!text-white',
            {'lg:scale-[50%]': isHeaderSticky}
          )}
          aria-label={'Man of Many Homepage'}
          prefetch={false}
        >
          <IconLogo 
            className="h-[28px] w-auto lg:h-[40px]"
            viewBox="0 0 404 40"
            preserveAspectRatio="xMidYMid meet"
          />
        </Link>

        <div className={classNames(
          'lg:col-start-3',
          'flex items-center justify-end gap-x-4'
        )}>
          <Button
            variant={'icon'}
            onClick={toggleSearch}
            className={classNames(
              'text-black hover:text-navy',
              'dark:text-white hover:dark:text-silver',
              'hidden lg:block transition-none',
            )}
            aria-label="Toggle Search"
          >
            {/* {isSearchVisible ? <IconClose/> : <IconSearch/>} */}
            <IconSearch/>
          </Button>

          {/* Fake background to prevent flashing */}
          <div className={classNames('relative')}>
            <Button
              variant={`fill`}
              size={isHeaderSticky ? 'small' : 'small'}
              onClick={toggleNewsletter}
              className={classNames([
                {'opacity-0': isHeaderSticky && enableShare}
              ])}
            >Subscribe</Button>

            {enableShare && (
              <PostShare
                className={classNames(
                  'absolute left-0 top-0 opacity-0 z-[1] w-full transition',
                  {'invisible': !isHeaderSticky},
                  {'opacity-100 visible': isHeaderSticky}
                )}
                variant={'fill'}
                size={`small`}
                shareDropdownArrowPosition={'right'}
              />
            )}
          </div>

          <ThemeToggle className={'hidden lg:block'}/>
        </div>
      </Container>
    </div>
  );
}
