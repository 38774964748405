import { gql } from "@apollo/client";
import classNames from "classnames";
import PropTypes from "prop-types";
import { memo, Suspense, useContext, useEffect, useRef } from "react";
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge'
import { getPrimaryCategory, sendIpsosEvent } from "@/lib/utils/helpers";
import Heading from "@/components/Heading/Heading";
import Container from "@/components/Container/Container";
import ContentWrapper from "@/components/ContentWrapper/ContentWrapper";
import SEO from "@/components/SEO/SEO";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import FeaturedImage from "@/components/FeaturedImage/FeaturedImage";
import { getStyledTitle } from "@/lib/utils/helpers";
import Link from "next/link";
import ImgYoutube from '@/lib/assets/images/soc-yt-c.svg';
import {getReactiveContent} from "@/lib/utils/helpers";
import PostsFeatured from "@/components/PostsFeatured/PostsFeatured";
import PostCard from "@/components/PostCard/PostCard";
import PostsGrid from "@/components/PostsGrid/PostsGrid";

const YouTubeEmbed = dynamic(() => import('react-social-media-embed').then(mod => mod.YouTubeEmbed), { ssr: false });

export default function Video(
  {
    className,
    video,
    siteSettings,
  }
) {
  const {
    title,
    content,
    seo,
    videoSettings,
    featuredImage,
    momRelatedVideos,
  } = video;

  const videoId = videoSettings?.youtubeVideoId;

  return (
    <div className={classNames('component')}>
      <Container className={`mom-section border-t-0 pt-0`}>
        <Breadcrumbs
          list={seo?.breadcrumbs}
          hideLast={true}
        />

        {videoId && <YouTubeEmbed
          className={classNames('mb-4 w-full aspect-video relative')}
          url={`https://www.youtube.com/watch?v=${videoId}`}
          width="100%"
          height="100%"
          placeholderDisabled={true}
          placeholderSpinnerDisabled={true}
          youTubeProps={{
            style: null,
            iframeClassName: 'absolute inset-0',
            opts: {
              playerVars: {
                autoplay: 1,
                controls: 0,
              }
            }
          }}
        />}

        <div className={classNames('lg:flex lg:flex-nowrap lg:justify-between lg:items-start gap-4 max-md:space-y-4')}>
          <div className={classNames('')}>
            <Heading level={`h1`} className={`mom-section-heading`}>{getStyledTitle({title, italicLast: true, delimiters: [':', ';', '  ', '|']})}</Heading>
            {content && (
              <div className={classNames('mt-4')}>
                {getReactiveContent({htmlString: content})}
              </div>
            )}
          </div>

          <div className={classNames('flex justify-center lg:justify-end')}>
            <Link href={siteSettings?.youtubeUrl} className={classNames('lg:shrink-0 lg:w-[150px] flex items-center gap-2 no-underline font-bold leading-4')}>
              <ImgYoutube className={classNames('w-11 h-8 text-white shrink-0 [&_path[fill="red"]]:!fill-[#FF0033]')} />
              <span>Subscribe to our channel</span>
            </Link>
          </div>
        </div>
      </Container>

      {momRelatedVideos?.length > 0 && (
        <Container className={`mom-section`}>
          <Heading level={`h2`} className={`mom-section-heading mb-4`}>Related Videos</Heading>

          <PostsGrid
            posts={momRelatedVideos}
            variant={`large`}
          />
        </Container>
      )}
    </div>
  );
}

export const VideoMemo = memo((props) => {
  return (
    <Video {...props} />
  );
});

Video.fragments = {
  keyVideo: 'VideoFragment',
  entryVideo: gql`
    ${SEO.fragments.entryPostType}
    ${PostCard.fragments.entryVideo}
    ${FeaturedImage.fragments.entry}
    fragment VideoFragment on Video {
      databaseId
      content
      uri
      title
      seo {
        ...${SEO.fragments.keyPostType}
      }
      videoSettings {
        youtubeVideoId
      }
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      momRelatedVideos {
        ...${PostCard.fragments.keyVideo}
      }
    }
  `,
};

Video.propTypes = {
  video: PropTypes.object.isRequired,
  className: PropTypes.string,
};
