import {setConfig} from '@faustwp/core';
import templates from './wp-templates';
import possibleTypes from './possibleTypes.json';

/**
 * Sample hooks
 */
/*class MoMHooks {
  apply(hooks) {
    const {addFilter} = hooks;

    addFilter(
      'possibleTemplatesList',
      'mom',
      (possibleTemplates, context) => {
        return possibleTemplates;
      },
    );

    addFilter(
      'seedQueryDocumentNode',
      'mom',
      (seedQuery, context) => {
        return seedQuery;
      },
    );
  }
}*/

/**
 * @type {import('@faustwp/core').FaustConfig}
 **/
setConfig({
  templates,
  possibleTypes,
  experimentalToolbar: true,
  /*plugins: [
    new MoMHooks()
  ],
  experimentalPlugins: [
    new MoMHooks()
  ],*/

  /**
   * MUST use along with WPGraphQL Smart Cache plugin
   * @link https://wpengine.com/builders/moving-get-post-requests-faust-js-1-0-wpgraphql/
   * @link https://github.com/wp-graphql/wp-graphql-smart-cache/blob/main/docs/persisted-queries.md
   */
  usePersistedQueries: true,
  useGETForQueries: false,
});
