import React from 'react';
import parse, {domToReact} from 'html-react-parser';

const ContentBlock = ({htmlString, classToExtract, onClick}) => {
  let extractedContent = null;

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class && domNode.attribs.class.includes(classToExtract)) {
        extractedContent = domToReact(domNode.children, options);
        return <></>;  // Return an empty fragment to remove this node from the main parse result
      }
    }
  };

  // Parse the entire HTML string
  parse(htmlString, options);

  // If we found the content, return it; otherwise, return null
  return extractedContent ? <div className={classToExtract} onClick={onClick}>{extractedContent}</div> : null;
};

export default ContentBlock;
