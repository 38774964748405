import classNames from 'classnames';
import React from "react";
import PostShare from "@/components/PostShare/PostShare";
import Button from "@/components/Button/Button";
import IconLink from "@/lib/assets/images/link.svg";
// import IconChecked from '@/lib/assets/images/check-b.svg';
import { Check } from 'lucide-react';
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useState } from "react";


export default function PostActions({ className }) {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [hasCopiedText, setHasCopiedText] = useState(false);

  let timeout;

  const handleCopy = () => {
    timeout = setTimeout(() => {
      clearTimeout(timeout);
      setHasCopiedText(false);
    }, 2000);

    setHasCopiedText(true);
    return copyToClipboard(window.location.href);
  };

  return (
    <div
      className={classNames(
        'd-flex max-md:py-4 max-md:mom-border max-md:border-y-2',
        className
      )}>
      <span className={`relative inline-block`}><PostShare variant={'fill'} /></span>

      <Button
        onClick={handleCopy}
        className={`ml-3 min-w-[147px] px-4`}
        aria-label={'Copy link'}
      >
        {(hasCopiedText && Boolean(copiedText)) ? (
          <><Check />Link Copied</>
        ) : (
          <><IconLink className={`mr-2`} />Copy Link</>
        )}
      </Button>
    </div>
  );
}

PostActions.propTypes = {};
